@media screen and (min-width: 1225px) {  
  .contact-us-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .contact-us-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .contact-details {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .contact-msg-n-form {
    flex: 1.3;
  }

  .contact-msg {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .contact-msg-2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .contact-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
  }

  .contact-outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .contact-outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  .contact-outline-input-field-subject {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .contact-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .contact-addresses {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 70px;
  }

  .office-box {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 300px; */
    width: 350px;
    padding-bottom: 20px;
  }

  .office-box-2 {
    margin: 30px 0px;
  }

  .office-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .office-location-icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin-right: 20px;
    background-color: #089247;
  }

  .office-location-icon-box > img {
    width: 50px;
  }

  .office-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .office-box > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 15px 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {  
  .contact-us-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .contact-us-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .contact-details {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .contact-msg-n-form {
    flex: 1.3;
  }

  .contact-msg {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .contact-msg-2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .contact-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
  }

  .contact-outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .contact-outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  .contact-outline-input-field-subject {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .contact-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .contact-addresses {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 70px;
  }

  .office-box {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 300px; */
    width: 350px;
    padding-bottom: 20px;
  }

  .office-box-2 {
    margin: 30px 0px;
  }

  .office-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .office-location-icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin-right: 20px;
    background-color: #089247;
  }

  .office-location-icon-box > img {
    width: 50px;
  }

  .office-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .office-box > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 15px 20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {  
  .contact-us-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .contact-us-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .contact-details {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }

  .contact-msg-n-form {
    flex: 1.3;
  }

  .contact-msg {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .contact-msg-2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .contact-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
  }

  .contact-outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .contact-outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  .contact-outline-input-field-subject {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .contact-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .contact-addresses {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 70px;
  }

  .office-box {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 300px; */
    padding-bottom: 20px;
  }

  .office-box-2 {
    margin: 30px 0px;
  }

  .office-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .office-location-icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin-right: 20px;
    background-color: #089247;
  }

  .office-location-icon-box > img {
    width: 50px;
  }

  .office-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .office-box > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 15px 20px;
  }
}

@media screen and (max-width: 480px) {  
  .contact-us-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .contact-us-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .contact-msg-n-form {
    flex: 1.3;
  }

  .contact-msg {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .contact-msg-2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .contact-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
  }

  .contact-outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .contact-outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  .contact-outline-input-field-subject {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .contact-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .contact-addresses {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .office-box {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 300px; */
    padding-bottom: 20px;
  }

  .office-box-2 {
    margin: 30px 0px;
  }

  .office-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .office-location-icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin-right: 20px;
    background-color: #089247;
  }

  .office-location-icon-box > img {
    width: 50px;
  }

  .office-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .office-box > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 15px 20px;
  }
}