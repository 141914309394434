@media screen and (min-width: 1225px) {
  .page-title-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .page-title-2-box {
    width: 75px;
    height: 75px;
    border: 5px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .page-title-2-first-char {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
  }
  
  .page-title-2-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .page-title-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .page-title-2-box {
    width: 50px;
    height: 50px;
    border: 5px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .page-title-2-first-char {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }
  
  .page-title-2-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
    flex: 1;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .page-title-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .page-title-2-box {
    width: 50px;
    height: 50px;
    border: 5px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .page-title-2-first-char {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }
  
  .page-title-2-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
    flex: 1;
  }
}

@media screen and (max-width: 480px) {
  .page-title-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .page-title-2-box {
    width: 50px;
    height: 50px;
    border: 5px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .page-title-2-first-char {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }
  
  .page-title-2-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 10px;
    flex: 1;
  }
}