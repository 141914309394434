@media screen and (min-width: 1225px) {
  .footer-container {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .cert-logos-container {
    height: 100px;
  }
  .cert-img {
    height: 75px;
    margin-right: 10px;
  }

  .footer-contact-info-n-form {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #fff;
    /* margin-top: 20px; */
    padding-top: 40px;
  }

  .footer-contact-info {
    flex: 1;
    padding-right: 20px;
  }

  .footer-contact-info-item {
    display: flex;
    flex-direction: row;
  }

  .footer-contact-info-item-1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
  }

  .footer-contact-info-item-icon {
    width: 35px;
  }

  .footer-contact-info-item-content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .footer-contact-info-item-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #fff;    
  }

  .footer-contact-info-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .footer-contact-info-item-text {
    white-space: pre-wrap;
    margin-top: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .link-2 {
    margin-top: 10px;
  }

  .footer-contact-form {
    flex: 1;
    padding-left: 20px;
  }

  .footer-contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    letter-spacing: 0.145em;

    color: #FFFFFF;
  }

  .footer-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .footer-rights {
    display: none;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .footer-container {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .cert-logos-container {
    height: 100px;
  }
  .cert-img {
    height: 75px;
    margin-right: 10px;
  }

  .footer-contact-info-n-form {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #fff;
    /* margin-top: 20px; */
    padding-top: 40px;
  }

  .footer-contact-info {
    flex: 1;
    padding-right: 20px;
  }

  .footer-contact-info-item {
    display: flex;
    flex-direction: row;
  }

  .footer-contact-info-item-1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
  }

  .footer-contact-info-item-icon {
    width: 35px;
  }

  .footer-contact-info-item-content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .footer-contact-info-item-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #fff;    
  }

  .footer-contact-info-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .footer-contact-info-item-text {
    white-space: pre-wrap;
    margin-top: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .link-2 {
    margin-top: 10px;
  }

  .footer-contact-form {
    flex: 1;
    padding-left: 20px;
  }

  .footer-contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    letter-spacing: 0.145em;

    color: #FFFFFF;
  }

  .footer-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .footer-rights {
    display: none;
  }

}

@media screen and (min-width: 620px) and (max-width: 1024px) {
  .footer-container {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .cert-logos-container {
    height: 100px;
  }
  .cert-img {
    height: 75px;
    margin-right: 10px;
  }

  .footer-contact-info-n-form {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #fff;
    /* margin-top: 20px; */
    padding-top: 40px;
  }

  .footer-contact-info {
    flex: 1;
    padding-right: 20px;
  }

  .footer-contact-info-item {
    display: flex;
    flex-direction: row;
  }

  .footer-contact-info-item-1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
  }

  .footer-contact-info-item-icon {
    width: 35px;
  }

  .footer-contact-info-item-content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .footer-contact-info-item-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #fff;    
  }

  .footer-contact-info-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .footer-contact-info-item-text {
    white-space: pre-wrap;
    margin-top: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .link-2 {
    margin-top: 10px;
  }

  .footer-contact-form {
    flex: 1;
    padding-left: 20px;
  }

  .footer-contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    letter-spacing: 0.145em;

    color: #FFFFFF;
  }

  .footer-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .footer-rights {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    align-items: center;
    margin-top: 50px;
    padding-top: 15px;
  }

  .footer-rights > p {
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
  }

}

@media screen and (max-width: 619px) {
  .footer-container {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .cert-logos-container {
    height: 75px;
  }
  .cert-img {
    height: 50px;
    margin-right: 10px;
  }

  .footer-contact-info-n-form {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    /* margin-top: 20px; */
    padding-top: 40px;
  }

  .footer-contact-info {
    flex: 1;
  }

  .footer-contact-info-item {
    display: flex;
    flex-direction: row;
  }

  .footer-contact-info-item-1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
  }

  .footer-contact-info-item-icon {
    width: 35px;
  }

  .footer-contact-info-item-content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .footer-contact-info-item-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #fff;    
  }

  .footer-contact-info-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    letter-spacing: 0.1em;
  }

  .footer-contact-info-item-text {
    white-space: pre-wrap;
    margin-top: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .link-2 {
    margin-top: 10px;
  }

  .footer-contact-form {
    flex: 1;
    margin-top: 30px;
  }

  .footer-contact-form-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    letter-spacing: 0.145em;

    color: #FFFFFF;
  }

  .footer-input-fields-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .outline-input-field-1 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-right: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;   
    width: 100%; 
  }

  .outline-input-field-2 {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    flex: 1;
    margin-left: 10px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
  }

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    margin-top: 20px;
    resize: none;
    width: 100%;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .footer-rights {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    align-items: center;
    margin-top: 30px;
    padding-top: 15px;
  }

  .footer-rights > p {
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
  }

}