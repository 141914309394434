.signature-section {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
}

.signature {
  font-family: "Monsieur La Doulaise";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  color: #FFFFFF;
}

.staff-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.staff-position {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}