@media screen and (min-width: 1225px) {   
  .about-us-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .about-us-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
  }

  .about-us-title-box {
    width: 75px;
    height: 75px;
    border: 5px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .about-us-title-first-char {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .about-us-title-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }

  .about-us-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .about-us-msg-section {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .about-us-msg {
    flex: 1;    
  }

  .about-us-msg-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us-msg-img > img {
    width: 75%;
  }
  .about-us-msg-intro {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;    
  }

  .about-us-msg-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .mission-vision {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .mission-vision-sub {
    width: 45%;
    height: 250px;
    /* background: rgba(58, 57, 57, 0.49); */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .mission-vision-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mission-vision-title-box {
    width: 75px;
    height: 75px;
    background: #089247;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .mission-vision-title-box > img {
    width: 50px;
  }

  .mission-vision-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
    color: #FFFFFF;

  }

  .mission-vision-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 20px;
    padding: 0px 20px;
  }

  .page-404-body {
    flex: 1;
    margin-left: 350px;
    margin-right: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .page-404-body > img {
    width: 400px;
    object-fit: contain;
  }
  
  .page-404-body > a {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {   
  .about-us-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .about-us-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .about-us-msg-section {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .about-us-msg {
    flex: 1;    
  }

  .about-us-msg-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us-msg-img > img {
    width: 75%;
  }



  .about-us-msg-intro {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;    
  }

  .about-us-msg-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .mission-vision {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .mission-vision-sub {
    width: 45%;
    height: 250px;
    /* background: rgba(58, 57, 57, 0.49); */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .mission-vision-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mission-vision-title-box {
    width: 75px;
    height: 75px;
    background: #089247;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .mission-vision-title-box > img {
    width: 50px;
  }

  .mission-vision-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
    color: #FFFFFF;

  }

  .mission-vision-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 20px;
    padding: 0px 20px;
  }

  .page-404-body {
    flex: 1;
    margin-left: 300px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .page-404-body > img {
    width: 400px;
    object-fit: contain;
  }
  
  .page-404-body > a {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {   
  .about-us-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .about-us-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .about-us-msg-section {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }

  .about-us-msg {
    flex: 1;    
  }

  .about-us-msg-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us-msg-img > img {
    width: 75%;
  }



  .about-us-msg-intro {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;    
  }

  .about-us-msg-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .mission-vision {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .mission-vision-sub {
    width: 45%;
    /* height: 250px; */
    min-height: 250px;
    /* background: rgba(58, 57, 57, 0.49); */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .mission-vision-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mission-vision-title-box {
    width: 75px;
    height: 75px;
    background: #089247;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .mission-vision-title-box > img {
    width: 50px;
  }

  .mission-vision-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    color: #FFFFFF;

  }

  .mission-vision-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 20px;
    padding: 0px 20px;
  }

  .page-404-body {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 110px;
    margin-bottom: 50px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .page-404-body > img {
    width: 300px;
    object-fit: contain;
  }
  
  .page-404-body > a {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {   
  .about-us-container {
    background-image: url('../../assets/images/mobile_bg_1.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .about-us-body {
    flex: 1;

    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .about-us-msg-section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .about-us-msg {
    /* flex: 1;     */
  }

  .about-us-msg-img {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us-msg-img > img {
    width: 75%;
  }

  .about-us-msg-intro {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;    
  }

  .about-us-msg-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .mission-vision {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .mission-vision-sub {
    margin-top: 50px;
    min-height: 250px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .mission-vision-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mission-vision-title-box {
    width: 75px;
    height: 75px;
    background: #089247;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .mission-vision-title-box > img {
    width: 50px;
  }

  .mission-vision-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    color: #FFFFFF;

  }

  .mission-vision-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 20px;
    padding: 0px 20px;
  }


  .page-404-body {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 150px;
    margin-bottom: 50px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .page-404-body > img {
    width: 300px;
    object-fit: contain;
  }
  
  .page-404-body > a {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
  }
}

