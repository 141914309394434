@media screen and (min-width: 1225px) {
  .profile-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .profile-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .profile-flip-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .profile-flip-control-btn-1 {
    background: #FFFFFF;
    border-radius: 15px 0px 0px 15px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-2 {
    background: #FFFFFF;
    border-radius: 0px 15px 15px 0px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-icon {
    width: 20px;
    height: 20px;
  }

  .profile-flip-control-count {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    color: #FFFFFF;
    font-size: 16px;
    padding: 0px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-book {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .profile-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .profile-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .profile-flip-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .profile-flip-control-btn-1 {
    background: #FFFFFF;
    border-radius: 15px 0px 0px 15px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-2 {
    background: #FFFFFF;
    border-radius: 0px 15px 15px 0px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-icon {
    width: 20px;
    height: 20px;
  }

  .profile-flip-control-count {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    color: #FFFFFF;
    font-size: 16px;
    padding: 0px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-book {
    margin-top: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .profile-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .profile-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 120px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .profile-flip-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .profile-flip-control-btn-1 {
    background: #FFFFFF;
    border-radius: 15px 0px 0px 15px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-2 {
    background: #FFFFFF;
    border-radius: 0px 15px 15px 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-icon {
    width: 15px;
    height: 15px;
  }

  .profile-flip-control-count {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    color: #FFFFFF;
    font-size: 16px;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-book {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .profile-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .profile-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 120px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .profile-flip-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .profile-flip-control-btn-1 {
    background: #FFFFFF;
    border-radius: 15px 0px 0px 15px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-2 {
    background: #FFFFFF;
    border-radius: 0px 15px 15px 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-flip-control-btn-icon {
    width: 15px;
    height: 15px;
  }

  .profile-flip-control-count {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    color: #FFFFFF;
    font-size: 16px;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-book {
    margin-top: 30px;
  }
}