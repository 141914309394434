.pdf-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1225px) {
  .compliance-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .compliance-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .compliance-contents {
    margin-top: 50px;
  }

  .compliance-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
  }

  .compliance-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .compliance-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .compliance-checked-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-left: 15px;
    align-items: flex-start;
  }

  .compliance-checked-item > img {
    width: 24px;
  }

  .compliance-checked-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }

  .certifications-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .certifications-img {
    height: 160px;
    padding: 25px 25px 0px 0px;
  }

  .certifications-grid {
    display: grid;
    grid-template-columns: 400px 400px;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 50px;
  }

  .certifications-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .certifications-img-section {
    height: 160px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .certifications-img-section > img {
    height: 70%;
    object-fit: contain;    
  }

  .certifications-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .certifications-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .certifications-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 40px;
  }

  .certifications-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .certifications-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .certifications-descr {
    margin: 0px 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: left;
    word-wrap:break-word;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .compliance-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .compliance-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .compliance-contents {
    margin-top: 50px;
  }

  .compliance-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
  }

  .compliance-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .compliance-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .compliance-checked-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-left: 15px;
    align-items: flex-start;
  }

  .compliance-checked-item > img {
    width: 24px;
  }

  .compliance-checked-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }

  .certifications-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .certifications-img {
    height: 120px;
    padding: 25px 25px 0px 0px;
  }

  .certifications-grid {
    display: grid;
    grid-template-columns: 350px 350px;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 50px;
  }

  .certifications-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .certifications-img-section {
    height: 140px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .certifications-img-section > img {
    height: 70%;
    object-fit: contain;    
  }

  .certifications-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .certifications-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .certifications-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 40px;
  }

  .certifications-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .certifications-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .certifications-descr {
    margin: 0px 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    word-wrap:break-word;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .compliance-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .compliance-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .compliance-contents {
    margin-top: 30px;
  }

  .compliance-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
  }

  .compliance-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .compliance-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .compliance-checked-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-left: 15px;
    align-items: flex-start;
  }

  .compliance-checked-item > img {
    width: 24px;
  }

  .compliance-checked-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }

  .certifications-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .certifications-img {
    height: 120px;
    padding: 25px 25px 0px 0px;
  }

  .certifications-grid {
    display: grid;
    grid-template-columns: 400px 400px;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 50px;
  }

  .certifications-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .certifications-img-section {
    height: 160px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .certifications-img-section > img {
    height: 70%;
    object-fit: contain;    
  }

  .certifications-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .certifications-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .certifications-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 40px;
  }

  .certifications-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .certifications-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .certifications-descr {
    margin: 0px 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    word-wrap:break-word;
  }
}

@media screen and (max-width: 480px) {
  .compliance-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .compliance-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .compliance-contents {
    margin-top: 30px;
  }

  .compliance-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
  }

  .compliance-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .compliance-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .compliance-checked-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-left: 15px;
    align-items: flex-start;
  }

  .compliance-checked-item > img {
    width: 24px;
  }

  .compliance-checked-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }

  .certifications-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .certifications-img {
    height: 80px;
    padding: 25px 25px 0px 0px;
  }

  .certifications-grid {
    display: grid;
    grid-template-columns: auto;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 50px;
  }

  .certifications-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .certifications-img-section {
    height: 120px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .certifications-img-section > img {
    height: 70%;
    object-fit: contain;    
  }

  .certifications-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .certifications-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .certifications-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 40px;
  }

  .certifications-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .certifications-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }

  .certifications-descr {
    margin: 0px 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    word-wrap:break-word;
  }
}
