@media screen and (min-width: 1225px)  {
  .vessels-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .vessels-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessels-grid {
    display: grid;
    grid-template-columns: 450px 450px;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 50px;
  }

  .vessel-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-card > img {
    height: 217px;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
  }

  .vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .vessel-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .vessels-stats {
    margin: 20px 40px;
  }

  .vessels-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .vessels-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .vessels-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-stats-item-status {
    min-width: 108px;
    padding: 5px 10px;
    height: 25px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  } 


  .vessels-stats-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .vessel-btn {
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;    
    cursor: pointer;
  }

  .vessel-btn > p {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .vessel-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .modal-overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-modal {
    position: absolute;
    top: 50px;
    left: 350px;
    right: 100px;
    bottom: 50px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
  }

  .vessel-modal:focus {outline:0;}

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 40px;
  }

  .vessel-modal-contents {
    display: flex;
    flex-direction: row;
    /* margin-top: 50px; */
    height: 100%;
    position: relative;
    flex: 1;
  }

  .vessel-modal-img {
    /* width: 80%; */
    height: 300px;
    margin-top: 30px;
    /* object-fit: contain; */
  }

  /* .vessel-modal-img > img {  
    width: 100%;
    object-fit: contain;
  } */

  .vessel-modal-info {
    flex: 1;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessel-modal-title {
    margin-top: 20px;
  }

  .vessel-modal-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 54px;
    color: #FFFFFF;
  }

  .vessel-modal-title > div {
    width: 90px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  .vessel-info-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .vessel-overview {
    margin: 30px 0px;
  }

  .vessels-overview-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .vessels-overview-item-label {
    flex: 1.5;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    /* margin-right: 15px; */
  }

  .vessels-overview-item-value {
    flex: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-overview-item-status {
    flex: 3;
  } 

  .vessels-overview-item-status-con > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 200px;
    height: 40px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
  }

  .vessel-info-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .vessel-request-quote {
    margin: 30px 0px;
  }

  .vessel-quote-form {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;
  }

  .vessel-outline-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 50px;
    /* margin-bottom: 20px; */
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    /* width: 100%; */
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }
 
}

@media screen and (min-width: 1025px) and (max-width: 1224px)  {
  .vessels-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .vessels-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessels-grid {
    display: grid;
    grid-template-columns: 400px 400px;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 50px;
  }

  .vessel-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-card > img {
    height: 217px;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
  }

  .vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .vessel-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .vessels-stats {
    margin: 20px 40px;
  }

  .vessels-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .vessels-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .vessels-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-stats-item-status {
    min-width: 108px;
    padding: 5px 10px;
    height: 25px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  } 

  .vessels-stats-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .vessel-btn {
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;    
    cursor: pointer;
  }

  .vessel-btn > p {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .vessel-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .modal-overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-modal {
    position: absolute;
    top: 50px;
    left: 300px;
    right: 50px;
    bottom: 50px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
  }

  .vessel-modal:focus {outline:0;}

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 40px;
  }

  .vessel-modal-contents {
    display: flex;
    flex-direction: row;
    /* margin-top: 50px; */
    height: 100%;
    position: relative;
    flex: 1;
  }

  .vessel-modal-img {
    /* width: 80%; */
    height: 300px;
    margin-top: 30px;
    /* object-fit: contain; */
  }

  /* .vessel-modal-img > img {  
    width: 100%;
    object-fit: contain;
  } */

  .vessel-modal-info {
    flex: 1;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessel-modal-title {
    margin-top: 20px;
  }

  .vessel-modal-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 54px;
    color: #FFFFFF;
  }

  .vessel-modal-title > div {
    width: 90px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  .vessel-info-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .vessel-overview {
    margin: 30px 0px;
  }

  .vessels-overview-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .vessels-overview-item-label {
    flex: 1.5;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    /* margin-right: 15px; */
  }

  .vessels-overview-item-value {
    flex: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-overview-item-status {
    flex: 3;
  } 

  .vessels-overview-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 200px;
    height: 40px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
  }

  .vessel-info-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .vessel-request-quote {
    margin: 30px 0px;
  }

  .vessel-quote-form {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;
  }

  .vessel-outline-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 50px;
    /* margin-bottom: 20px; */
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    /* width: 100%; */
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }
 
}

@media screen and (min-width: 481px) and (max-width: 1024px)  {
  .vessels-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .vessels-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessels-grid {
    display: grid;
    grid-template-columns: 400px 400px;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 50px;
  }

  .vessel-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-card > img {
    height: 217px;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
  }

  .vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .vessel-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .vessels-stats {
    margin: 20px 40px;
  }

  .vessels-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .vessels-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .vessels-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-stats-item-status {
    min-width: 108px;
    padding: 5px 10px;
    height: 25px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  } 

  .vessels-stats-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .vessel-btn {
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;    
    cursor: pointer;
  }

  .vessel-btn > p {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .vessel-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .modal-overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-modal {
    position: absolute;
    top: 100px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
  }

  .vessel-modal:focus {outline:0;}

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 40px;
  }

  .vessel-modal-contents {
    display: flex;
    flex-direction: row;
    /* margin-top: 50px; */
    height: 100%;
    position: relative;
    flex: 1;
  }

  .vessel-modal-img {
    /* width: 80%; */
    height: 300px;
    margin-top: 30px;
    /* object-fit: contain; */
  }

  /* .vessel-modal-img > img {  
    width: 100%;
    object-fit: contain;
  } */

  .vessel-modal-info {
    flex: 1;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessel-modal-title {
    margin-top: 20px;
  }

  .vessel-modal-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 54px;
    color: #FFFFFF;
  }

  .vessel-modal-title > div {
    width: 90px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  .vessel-info-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .vessel-overview {
    margin: 30px 0px;
  }

  .vessels-overview-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .vessels-overview-item-label {
    flex: 1.5;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    /* margin-right: 15px; */
  }

  .vessels-overview-item-value {
    flex: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-overview-item-status {
    flex: 3;
  } 

  .vessels-overview-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 200px;
    height: 40px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
  }

  .vessel-info-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .vessel-request-quote {
    margin: 30px 0px;
  }

  .vessel-quote-form {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;
  }

  .vessel-outline-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 50px;
    /* margin-bottom: 20px; */
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    /* width: 100%; */
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }
 
}

@media screen and (max-width: 480px) {
  .vessels-container {
    background-image: url('../../assets/images/bg_5.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .vessels-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessels-grid {
    display: grid;
    grid-template-columns: auto;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 50px;
  }

  .vessel-card{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-card > img {
    height: 217px;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
  }

  .vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .vessel-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .vessels-stats {
    margin: 20px 40px;
  }

  .vessels-stats-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .vessels-stats-item-label {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-right: 15px;
  }

  .vessels-stats-item-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-stats-item-status {
    min-width: 108px;
    padding: 5px 10px;
    height: 25px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  } 

  .vessels-stats-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .vessel-btn {
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;    
    cursor: pointer;
  }

  .vessel-btn > p {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .vessel-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .modal-overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .vessel-modal {
    position: absolute;
    top: 100px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
  }

  .vessel-modal:focus {outline:0;}

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 30px;
  }

  .vessel-modal-contents {
    display: flex;
    flex-direction: row;
    /* margin-top: 50px; */
    height: 100%;
    position: relative;
    flex: 1;
  }

  .vessel-modal-img {
    width: 80%;
    margin-top: 30px;
    /* object-fit: contain; */
  }

  .vessel-modal-info {
    flex: 1;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .vessel-modal-title {
    margin-top: 20px;
  }

  .vessel-modal-title > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #FFFFFF;
  }

  .vessel-modal-title > div {
    width: 90px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }

  .vessel-info-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .vessel-overview {
    margin: 30px 0px;
  }

  .vessels-overview-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .vessels-overview-item-label {
    flex: 1.5;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    /* margin-right: 15px; */
  }

  .vessels-overview-item-value {
    flex: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
 
  .vessels-overview-item-status {
    flex: 3;
  } 

  .vessels-overview-item-status > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 200px;
    height: 40px;
    /* background: rgba(0, 146, 65, 0.75); */
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
  }

  .vessel-info-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .vessel-request-quote {
    margin: 30px 0px;
  }

  .vessel-quote-form {
    display: grid;
    grid-template-columns: auto;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 30px;
  }

  .vessel-outline-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 50px;
    /* margin-bottom: 20px; */
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    /* width: 100%; */
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }
 
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type=date] {
  text-align: right;
}

input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.status-in-use {
  background: orange;
}

.status-available {
  background: rgba(0, 146, 65, 0.75);
}

.status-under-maintenance {
  background: #261473;
}