@media screen and (min-width: 1225px) {
  .page-title-3 {
    display: flex;
    flex-direction: column;
  }
  
  .page-title-3-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .page-title-3-underline {
    width: 93px;
    height: 2px;
    background: #FFFFFF;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .page-title-3 {
    display: flex;
    flex-direction: column;
  }
  
  .page-title-3-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .page-title-3-underline {
    width: 93px;
    height: 2px;
    background: #FFFFFF;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .page-title-3 {
    display: flex;
    flex-direction: column;
  }
  
  .page-title-3-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .page-title-3-underline {
    width: 93px;
    height: 2px;
    background: #FFFFFF;
  }
}

@media screen and (max-width: 480px) {
  .page-title-3 {
    display: flex;
    flex-direction: column;
  }
  
  .page-title-3-chars {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .page-title-3-underline {
    width: 93px;
    height: 2px;
    background: #FFFFFF;
  }
}