
.btn-1 {
  text-decoration: none;
}

@media screen and (min-width: 1225px) {  
  .home-container {    
    height: 100vh;        
  }

  .home-slide-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: 100vh;
  }

  .slide-1 {
    background-image: url('../../assets/images/bg_8.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-2 {
    background-image: url('../../assets/images/sis-anissa.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-3 {
    background-image: url('../../assets/images/bg_7.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-4 {
    background-image: url('../../assets/images/bg_6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .home-slide-item-index {
    font-weight: 300;
    font-size: 48px;
    color: #FFFFFF;
  }

  .home-slide-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bolder;
    font-size: 56px;
    text-transform: capitalize;
    color: #FFFFFF;
    white-space: pre-wrap;
    padding-top: 0px;
    line-height: 115%;
    letter-spacing: 0.02em;
    margin-top: 100px;
    text-align: left;
  }

  .home-slide-item-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    white-space: pre-wrap;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
  }

  .btn-1 {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .home-footer {
    position: absolute;
    bottom: 50px;    
    width: 100%;
    /* right: 100px; */
  }

  .home-slide-controls {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 350px;
    padding-right: 100px;
  }

  .home-slide-controls-btns {
    width: 50px;
    margin: 0px 10px;
    cursor: pointer;
  }

  .home-remarks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 350px;
    padding-right: 100px;
  }

  .home-remarks-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }

  .home-remarks-item > img {
    width: 35px;
  }

  .home-remarks-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-left: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .home-container {    
    height: 100vh;
    
  }

  .home-slide-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: 100vh;
  }

  .slide-1 {
    background-image: url('../../assets/images/bg_8.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-2 {
    background-image: url('../../assets/images/sis-anissa.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-3 {
    background-image: url('../../assets/images/bg_7.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-4 {
    background-image: url('../../assets/images/bg_6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .home-slide-item-index {
    font-weight: 300;
    font-size: 36px;
    color: #FFFFFF;
  }

  .home-slide-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    text-transform: capitalize;
    color: #FFFFFF;
    white-space: pre-wrap;
    padding-top: 0px;
    line-height: 115%;
    letter-spacing: 0.02em;
    margin-top: 100px;
    text-align: left;
  }

  .home-slide-item-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    white-space: pre-wrap;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
  }

  .btn-1 {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .home-footer {
    position: absolute;
    bottom: 50px;    
    width: 100%;
    /* right: 100px; */
  }

  .home-slide-controls {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 300px;
    padding-right: 50px;
  }

  .home-slide-controls-btns {
    width: 50px;
    margin: 0px 10px;
    cursor: pointer;
  }

  .home-remarks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 300px;
    padding-right: 50px;
  }

  .home-remarks-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
  }

  .home-remarks-item > img {
    width: 30px;
  }

  .home-remarks-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
    margin-left: 16px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .home-container {    
    height: 100vh;
    
  }

  .home-slide-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 100px 20px;
    height: 100vh;
  }

  .slide-1 {
    background-image: url('../../assets/images/bg_8.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-2 {
    background-image: url('../../assets/images/sis-anissa.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-3 {
    background-image: url('../../assets/images/bg_7.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-4 {
    background-image: url('../../assets/images/bg_6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .home-slide-item-index {
    font-weight: 300;
    font-size: 36px;
    margin-top: 30px;
    color: #FFFFFF;
  }

  .home-slide-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-transform: capitalize;
    color: #FFFFFF;
    white-space: pre-wrap;
    padding-top: 0px;
    line-height: 115%;
    letter-spacing: 0.02em;
    margin-top: 50px;
    text-align: left;
  }

  .home-slide-item-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    white-space: pre-wrap;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
  }

  .btn-1 {
    width: 200px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .home-footer {
    position: absolute;
    bottom: 20px;    
    width: 100%;
    /* right: 100px; */
  }

  .home-slide-controls {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 50px;
    padding-right: 50px;
  }

  .home-slide-controls-btns {
    width: 50px;
    margin: 0px 10px;
    cursor: pointer;
  }

  .home-remarks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
  }

  .home-remarks-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  .home-remarks-item > img {
    width: 20px;
  }

  .home-remarks-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
    margin-left: 16px;
  }
}

@media screen and (max-width: 480px)  {
  .home-container {    
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
  }

  .home-slide-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 100px 20px;
    height: 100vh;
    /* height: 100%; */
    overflow-y: hidden;
  }

  .slide-1 {
    background-image: url('../../assets/images/bg_8.jpg');    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-2 {
    background-image: url('../../assets/images/sis-anissa.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-3 {
    background-image: url('../../assets/images/mobile_bg_1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-4 {
    background-image: url('../../assets/images/bg_6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .home-slide-item-index {
    font-weight: 300;
    font-size: 36px;
    margin-top: 30px;
    color: #FFFFFF;
  }

  .home-slide-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
    white-space: pre-wrap;
    padding-top: 0px;
    line-height: 115%;
    letter-spacing: 0.02em;
    margin-top: 100px;
    text-align: left;
  }

  .home-slide-item-sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    color: #FFFFFF;
    white-space: pre-wrap;
    margin-bottom: 20px;
    text-align: left;
  }

  .btn-1 {
    width: 200px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  .home-footer {
    position: absolute;
    bottom: 10px;    
    width: 100%;
    /* right: 100px; */
  }

  .home-slide-controls {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-slide-controls-btns {
    width: 50px;
    margin: 0px 10px;
  }

  .home-remarks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-remarks-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  .home-remarks-item > img {
    width: 20px;
  }

  .home-remarks-item > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
    margin-left: 6px;
  }
}
