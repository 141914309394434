@media screen and (min-width: 1225px) {
  .services-container {
    background-image: url('../../assets/images/bg_11.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .services-body {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .services-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 50px;
  }

  .services-card {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 400px; */
  }

  .services-card > img {
    /* width: 450px; */
    height: 217px;
  }

  .service-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .service-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 0px 40px;
  }

  .service-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .service-btn {
    text-decoration: none;
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  .service-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .service-details {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }

  .service-details-content {
    flex: 1.3;
    margin-right: 40px;
  }

  .sub-service-1 {
    margin-top: 40px;
  }

  .sub-service-checked-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sub-service-checked-title > img {
    width: 35px;
  }

  .sub-service-checked-title > p {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .sub-service-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .sub-service-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .service-details-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-details-img > img {
    width: 75%;
  }

  .service-content-p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .service-content-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .service-content-list {
    margin-left: 20px;
  }

  .service-content-list-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .services-container {
    background-image: url('../../assets/images/bg_11.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .services-body {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .services-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 50px;
  }

  .services-card {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 400px; */
  }

  .services-card > img {
    /* width: 450px; */
    height: 180px;
  }

  .service-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .service-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 0px 40px;
  }

  .service-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .service-btn {
    text-decoration: none;
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  .service-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .service-details {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }

  .service-details-content {
    flex: 1.3;
    margin-right: 40px;
  }

  .sub-service-1 {
    margin-top: 40px;
  }

  .sub-service-checked-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sub-service-checked-title > img {
    width: 35px;
  }

  .sub-service-checked-title > p {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .sub-service-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .sub-service-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .service-details-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-details-img > img {
    width: 75%;
  }

  .service-content-p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .service-content-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .service-content-list {
    margin-left: 20px;
  }

  .service-content-list-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }
}

@media screen and (min-width: 751px) and (max-width: 1024px) {
  .services-container {
    background-image: url('../../assets/images/bg_11.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .services-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .services-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 30px;
  }

  .services-card {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 400px; */
  }

  .services-card > img {
    /* width: 450px; */
    width: 100%;
    height: 180px;
  }

  .service-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .service-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 0px 40px;
  }

  .service-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .service-btn {
    text-decoration: none;
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  .service-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .service-details {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .service-details-content {
    flex: 1.3;
  }

  .sub-service-1 {
    margin-top: 40px;
  }

  .sub-service-checked-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sub-service-checked-title > img {
    width: 35px;
  }

  .sub-service-checked-title > p {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .sub-service-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .sub-service-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .service-details-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .service-details-img > img {
    width: 65%;
  }

  .service-content-p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .service-content-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .service-content-list {
    margin-left: 20px;
  }

  .service-content-list-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }
}

@media screen and (max-width: 750px) {
  .services-container {
    background-image: url('../../assets/images/bg_11.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .services-body {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .services-grid {
    display: grid;
    grid-template-columns: auto;
    column-gap: 90px;
    row-gap: 80px;
    margin-top: 30px;
  }

  .services-card {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* height: 400px; */
  }

  .services-card > img {
    /* width: 450px; */
    width: 100%;
    height: 180px;
  }

  .service-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 40px 0px 40px;
  }

  .service-descr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 0px 40px;
  }

  .service-title-underline {
    width: 91px;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 0px 40px 15px 40px;
  }

  .service-btn {
    text-decoration: none;
    width: 117px;
    height: 40px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 40px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  .service-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .service-details {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .service-details-content {
    flex: 1.3;
  }

  .sub-service-1 {
    margin-top: 40px;
  }

  .sub-service-checked-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sub-service-checked-title > img {
    width: 35px;
  }

  .sub-service-checked-title > p {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .sub-service-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
  }

  .sub-service-p-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  .service-details-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .service-details-img > img {
    width: 65%;
  }

  .service-content-p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .service-content-p-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 15px;
  }

  .service-content-list {
    margin-left: 20px;
  }

  .service-content-list-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 10px;
  }
}